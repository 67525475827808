// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7772eab8-dbaa-4aaa-9611-61fc8d2e54e2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ocJgRI1GR",
    "aws_user_pools_web_client_id": "4rqg3rtkd5jah3bnbheaus70m4",
    "oauth": {},
    "aws_user_files_s3_bucket": "researchpalimagestoragedev-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
